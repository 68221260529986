import axios from 'axios';
import React, { useState } from 'react'
import Logo from '../../assets/images/img/logo_all_white.png'
import { useAuthContext } from '../../hooks/useAuthContext';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import CircularPreloader from '../../components/molecules/preloaders/circular/CircularPreloader';

function Login() {

    const baseURL = process.env.REACT_APP_BASEURL;
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const { dispatch, user } = useAuthContext();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        const userDetails = {
            email: email,
            password: password
        }

        await axios.post(`${baseURL}admin/auth/signin`, userDetails, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((response) => {

            localStorage.setItem('user', JSON.stringify(response.data));

            if (response) {

                // Update the Auth Context
                dispatch({
                    type: 'LOGIN',
                    payload: response.data
                });

                toast.success("Log In Successful. ");
                setLoading(false);
                navigate('/dashboard');
            }
        }).catch((error) => {
            if (error) {
                toast.error("Incorrect login details. ");
                setError("Incorrect Login Details");
                setLoading(false)
            }
        })
    }

    localStorage.setItem('name', "Folaranmi")


    return (
        <div className='login flex items-center 
            justify-center w-screen h-screen bg-gray-200 flex-col loginBG'>

            <img src={Logo} alt="Hostel.ng Logo" className='h-10 mb-5' />

            <div className="flex flex-col items-center rounded-md w-auto min-h-fit bg-white my-6 p-8">
                {error && <div className='w-full rounded bg-red-100 py-2 px-5 mb-5 text-red-700 text-sm'>Error dey occur</div>}
                <form onSubmit={(e) => handleLogin(e)}>
                    <input
                        type="email"
                        placeholder="Email address"
                        className="input input-bordered w-full mb-6"
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <input
                        type="password"
                        placeholder="Password"
                        className="input input-bordered w-full mb-6"
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <button className='bg-blue-700 hover:bg-blue-500 text-white py-3 
                        px-7 font-medium text-md rounded-md transition ease-in-out delay-150
                        disabled:bg-blue-200 disabled:cursor-not-allowed'
                        disabled={!email || !password}
                    >
                        {loading ? (<div className='flex'>loading <CircularPreloader small /> </div> ) : "Sign In"}
                    </button>
                </form>
            </div>

            <Toaster />
        </div>
    )
}

export default Login